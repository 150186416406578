import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { css } from 'glamor';
import ClientFonts from '../client-ui/shared/ClientFonts';
import Landing from '../client-ui/landing/Landing';

// SEARCH: [DELAY-FIX]
// SEARCH: [REMOVE-IN-PRODUCTION]
// SEARCH: [HERE]

const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
}

html, body {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
}

body {
  position: relative;
  width: 100%;
}

img{
  max-width:100%;
}
`;

export const ROOT_URL = "https://slap-kard.web.app"
// markup
export default () => {
    return (
        <>
            <div style={{ minWidth : '100vw', minHeight : '100vh'}}>
                {/* <ClientFonts/> */}
                <ClientFonts/>
                <GlobalStyle />
                <Landing/>
            </div>
        </>
    );
};


